<template>
  <AuthForm>
    <form class="mt-8 space-y-6" role="form">
      <Input
        id="email-address"
        name="email"
        type="email"
        placeholder="Email address"
        v-model="form.email"
        label="Email Address"
      />
      <span class="mt-2"></span>
      <Input
        id="password"
        name="password"
        type="password"
        placeholder="Password"
        v-model="form.password"
        label="Password"
      />

      <div class="flex items-center justify-between">
        <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            Forgot your password?
          </a>
        </div>
      </div>

      <div>
        <button
          type="submit"
          class="
            group
            relative
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            text-sm
            font-medium
            rounded-md
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click.prevent="login"
        >
          Sign in
        </button>
      </div>
    </form>
    <SocialLoginLinks />
  </AuthForm>
</template>

<script lang="js">
import AuthForm from "@/components/AuthForm";
import Loader from "@/components/Loader";
import Input from "@/components/Input";
import SocialLoginLinks from "@/components/SocialLoginLinks";

export default {
    name: "Login",
    components: {
        AuthForm,
        Input,
        SocialLoginLinks,
    },
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            errors: {},
            loading: false
        }
    },
    methods: {
      login() {
        // todo
      }
    }
}
</script>
