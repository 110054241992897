<template>
  <div class="rounded-md shadow-sm">
    <div>
      <label
        :for="id"
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >{{ label }}</label
      >
      <input
        :id="id"
        :name="name"
        :type="type"
        autocomplete="off"
        :required="required"
        class="
          appearance-none
          rounded-none
          relative
          block
          w-full
          px-3
          py-2
          border border-gray-300
          placeholder-gray-500
          text-gray-900
          rounded-t-md
          focus:outline-none
          focus:ring-indigo-500
          focus:border-indigo-500
          focus:z-10
          sm:text-sm
        "
        :class="error? 'border-red-500' :''"
        :placeholder="placeholder"
        :value="modelValue"
        @input="updateValue"
      />
      <span class="text-red-500 p-2 mt-3" v-if="error"> {{ error }} </span>
    </div>
  </div>
</template>

<script lang="js">
export default {
    name: "Input",
    props: {
        id: {
            type: String,
            default: "email"
        },
        type: {
            type: String,
            default: "text",
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        updateValue(e) {
            this.$emit('update:modelValue', e.target.value);
        }

    }
}
</script>
